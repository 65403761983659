import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import GenericConfirmationCard from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import { InputSwitchLarge } from 'common/components/fields/InputSwitchLarge';
import { SelectMultiple } from 'common/components/fields/SelectMultiple';
import { SelectLabel } from 'common/components/fields/SelectLabel';
import states from 'helpers/states.json';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import { requiredFieldError } from 'modules/owner/pages/actions/actionsFile';
import { getContactsByStationIdSetForm } from 'modules/owner/pages/contacts/contactsActions';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormMeta, getFormSyncErrors, getFormValues, reduxForm } from 'redux-form';
import NewSelectMultiple from '../../../../../common/components/fields/NewSelectMultiple';
import { getList as getPlansList } from '../../plans/plansActions';
import { getStationActiveActions } from '../activeActions/activeActionsScripts';

class Publish extends Component {
  constructor(props) {
    super(props);
    this.state = {
      states: states,
      submitted: false,
      poppedUp: false,
    };

    this.props.getStationActiveActions(this.props.auth.user.establishments[0].stations[0].id);
    this.props.getContactsByStationIdSetForm(
      this.props.auth.user.establishments[0].stations[0].id,
      this.props.formValues?.segment_users,
      this.props.form
    );
    this.props.getPlansList(this.props.auth.user.establishments[0].stations[0].id);
  }

  componentDidMount() {
    const orderedStates = [
      {
        id: 10,
        sigla: 'all',
        nome: 'Todos',
      },
      ...states,
    ];
    orderedStates.sort(function (a, b) {
      if (a.id === 10) {
        return -1;
      }
      if (b.id === 10) {
        return 1;
      }
      if (a.sigla < b.sigla) {
        return -1;
      }
      if (a.sigla > b.sigla) {
        return 1;
      }
      return 0;
    });

    this.setState({ states: orderedStates });
  }

  componentDidUpdate(props) {
    if (!_.isEmpty(this.props.submitErrors) && this.state.submitted && !this.state.poppedUp) {
      this.props.requiredFieldError();
      this.setState({ poppedUp: true });
      this.setState({ submitted: false });
    }
    if (this.props.submitErrors !== props.submitErrors && this.state.poppedUp) {
      this.setState({ poppedUp: false });
    }
  }

  onSubmit = (values) => {
    console.log(values);
    if (values.categories_field) {
      values.categories = values.categories_field.map((item) => item.id);
    }
    if (values.groupings_field) {
      values.groupings = values.groupings_field.map((item) => item.id);
    }
    if (values.has_target) {
      values.segment_users = values.segment_users.map((item) => item?.id);
    }
    values.submitFromButton = true;
    // console.log(values);
    this.props.onClickProgress(values);
  };

  onSubmitSec = (values) => {
    values.categories = values.categories_field.map((item) => item.id);
    values.groupings = values.groupings_field.map((item) => item.id);
    if (values.has_target) {
      values.segment_users = values.segment_users.map((item) => item.id);
    }
    values.submitFromButton = true;
    // console.log(values);
    this.props
      .onClickProgress(values)
      .then(() => {
        this.props.closeModal();
      })
      .catch(() => {});
  };

  render() {
    const statesList = this.state.states.map((item, index) => ({
      value: item.sigla,
      label: item.nome,
    }));

    const plansList = this.props.plans.list.content.map((item, index) => ({
      value: item.id,
      label: item.name,
    }));

    const ageError = "A idade 'De' está menor que 'Para'";

    return (
      <>
        <Form role="form" onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate>
          <div
            style={{
              overflowY: 'scroll',
              height: isMobile ? 'calc(100vh - 250px)' : 'calc(100vh - 355px)',
              overflowX: 'hidden',
              paddingRight: isMobile ? 0 : 15,
            }}>
            <div style={{ marginTop: 20 }}>
              <Typography style={{ fontSize: 16, fontWeight: '400' }} variant="subtitle2">
                Agendamento da publicação
              </Typography>
              <MaterialGrid container spacing={3}>
                <MaterialGrid item xs={12} md={6}>
                  <Field
                    component={InputLabelOutlined}
                    label={'Início'}
                    name="start"
                    type="datetime-local"
                    required
                    validate={[FORM_RULES.required]}
                    margin="normal"
                  />
                </MaterialGrid>
                <MaterialGrid item xs={12} sm={12} md={6}>
                  <Field
                    component={InputLabelOutlined}
                    label={'Fim'}
                    name="end"
                    type="datetime-local"
                    required
                    validate={[FORM_RULES.required]}
                    margin="normal"
                  />
                </MaterialGrid>
              </MaterialGrid>
            </div>
            <div style={{ marginTop: 20 }}>
              <Typography style={{ fontSize: 16, fontWeight: '400' }} variant="subtitle2">
                Agendamento da participação
              </Typography>
              <MaterialGrid container spacing={3}>
                <MaterialGrid item xs={12} md={6}>
                  <Field
                    component={InputLabelOutlined}
                    label={'Início'}
                    name="participationStart"
                    type="datetime-local"
                    required
                    validate={[FORM_RULES.required]}
                    margin="normal"
                  />
                </MaterialGrid>
                <MaterialGrid item xs={12} sm={12} md={6}>
                  <Field
                    component={InputLabelOutlined}
                    label={'Fim'}
                    name="participationEnd"
                    type="datetime-local"
                    required
                    validate={[FORM_RULES.required]}
                    margin="normal"
                  />
                </MaterialGrid>
              </MaterialGrid>
            </div>
            {this.props.formValues ? (
              this.props.formValues.is_event ? (
                <MaterialGrid container spacing={3}>
                  <MaterialGrid item xs={12} md={6}>
                    <Field
                      component={InputLabelOutlined}
                      label={'Data de início do evento'}
                      name="event_start"
                      type="datetime-local"
                      disabled={
                        this.props.disabled || this.props.router.params.action === 'proposta'
                      }
                      required
                      validate={[FORM_RULES.required]}
                      margin="normal"
                    />
                  </MaterialGrid>
                  <MaterialGrid item xs={12} sm={12} md={6}>
                    <Field
                      component={InputLabelOutlined}
                      label={'Data do fim do evento'}
                      name="event_end"
                      type="datetime-local"
                      disabled={
                        this.props.disabled || this.props.router.params.action === 'proposta'
                      }
                      required
                      validate={[FORM_RULES.required]}
                      margin="normal"
                    />
                  </MaterialGrid>
                </MaterialGrid>
              ) : null
            ) : null}

            <MaterialGrid item xs={12}>
              <Field component={InputSwitchLarge} label={'Ordem'} name="hasPriority" />
            </MaterialGrid>
            {this.props.formValues ? (
              this.props.formValues.hasPriority ? (
                <MaterialGrid item xs={12} sm={12}>
                  <Field
                    component={InputLabelOutlined}
                    label="1-1000"
                    helperText="O número da ordem determina a posição desta atividade na estação,
ou seja, ordem 1 faz a atividade aparecer em primeiro lugar, 2 em
segundo e assim por diante."
                    name="priority"
                    required
                    margin="normal"
                  />
                </MaterialGrid>
              ) : null
            ) : null}
            {/** ********************Se vai ou não ter segmentação *********************/}
            <MaterialGrid item xs={12}>
              <Field
                component={InputSwitchLarge}
                label={'Limitar atividade por público alvo'}
                name="has_target"
                disabled={this.props.router.params.action === 'proposta'}
                required
                margin="normal"
              />
            </MaterialGrid>
            {this.props.formValues?.has_target ? (
              <>
                {/** ********************TIPO DE SEGMENTAÇÃO *********************/}
                <Typography style={{ fontSize: 16, fontWeight: '400' }} variant="subtitle2">
                  Definir público alvo
                </Typography>
                <MaterialGrid container spacing={3}>
                  <MaterialGrid item xs={12} sm={12} md={6}>
                    <Field
                      component={SelectLabel}
                      type="text"
                      label={`Tipo de segmentação`}
                      name={`segmentation_type`}
                      disabled={this.props.router.params.action === 'proposta'}
                      margin="normal"
                      options={[
                        { value: 'public', label: 'Por público' },
                        { value: 'users', label: 'Para usuários específicos' },
                      ]}
                      validate={[FORM_RULES.required]}
                    />
                  </MaterialGrid>
                </MaterialGrid>

                {/** ********************Para usuários específicos *********************/}
                {this.props.formValues ? (
                  this.props.formValues.segmentation_type === 'users' ? (
                    <MaterialGrid container spacing={3}>
                      <MaterialGrid item xs={12} sm={12} md={6}>
                        <Field
                          component={NewSelectMultiple}
                          label={'Escolha um ou mais usuários'}
                          options={this.props.contacts.list.content
                            .filter((item) => item.email)
                            .map((item) => ({
                              id: item.id,
                              title: item.email,
                            }))}
                          multiple
                          disabled={this.props.router.params.action === 'proposta'}
                          name="segment_users"
                          required
                          validate={[FORM_RULES.required]}
                          margin="normal"
                        />
                      </MaterialGrid>
                    </MaterialGrid>
                  ) : this.props.formValues.segmentation_type === 'public' ? (
                    /** ******************** POR PUBLICO *********************/
                    <>
                      <MaterialGrid container spacing={3}>
                        <MaterialGrid item xs={12} sm={12} md={6} lg={3}>
                          <Field
                            component={InputLabelOutlined}
                            label="De (anos)"
                            name="from"
                            type="number"
                            disabled={this.props.router.params.action === 'proposta'}
                            validate={[FORM_RULES.required]}
                            required
                            margin="normal"
                            helperText={
                              this.props.formValues
                                ? parseInt(this.props.formValues.from) >
                                  parseInt(this.props.formValues.to)
                                  ? ageError
                                  : null
                                : null
                            }
                          />
                        </MaterialGrid>
                        <MaterialGrid item xs={12} sm={12} md={6} lg={3}>
                          <Field
                            component={InputLabelOutlined}
                            label="Até (anos)"
                            name="to"
                            disabled={this.props.router.params.action === 'proposta'}
                            type="number"
                            validate={[FORM_RULES.required]}
                            required
                            helperText={
                              this.props.formValues
                                ? parseInt(this.props.formValues.from) >
                                  parseInt(this.props.formValues.to)
                                  ? ageError
                                  : null
                                : null
                            }
                            margin="normal"
                          />
                        </MaterialGrid>
                      </MaterialGrid>
                      <Typography style={{ fontSize: 16, fontWeight: '400' }} variant="subtitle2">
                        Local de divulgação
                      </Typography>
                      <MaterialGrid container spacing={3}>
                        <MaterialGrid item xs={12} sm={12} md={6}>
                          <Field
                            component={SelectMultiple}
                            label={'Estados que poderão participar'}
                            options={statesList}
                            multiple
                            disabled={this.props.router.params.action === 'proposta'}
                            name="states"
                            required
                            validate={[FORM_RULES.required]}
                            margin="normal"
                          />
                        </MaterialGrid>
                      </MaterialGrid>
                      {plansList.length > 0 ? (
                        <>
                          <Typography
                            style={{ fontSize: 16, fontWeight: '400' }}
                            variant="subtitle2">
                            Segmentar por Plano
                          </Typography>
                          <MaterialGrid container spacing={3}>
                            <MaterialGrid item xs={12} sm={12} md={6}>
                              <Field
                                component={SelectMultiple}
                                label={'Escolha o plano'}
                                options={plansList}
                                multiple
                                name="segment_plans"
                                margin="normal"
                              />
                            </MaterialGrid>
                          </MaterialGrid>
                        </>
                      ) : null}
                      {this.props.auth.user.establishments?.[0]?.entities?.length > 0 ? (
                        <>
                          <Typography
                            style={{ fontSize: 16, fontWeight: '400' }}
                            variant="subtitle2">
                            Segmentar por Entidade
                          </Typography>
                          <MaterialGrid container spacing={3}>
                            <MaterialGrid item xs={12} sm={12} md={6}>
                              <Field
                                component={SelectMultiple}
                                label={'Escolha uma ou mais'}
                                options={this.props.auth.user.establishments?.[0]?.entities?.map(
                                  (item) => ({
                                    value: item.id,
                                    label: item.name,
                                  })
                                )}
                                multiple
                                disabled={this.props.router.params.action === 'proposta'}
                                name="entities"
                                margin="normal"
                              />
                            </MaterialGrid>
                          </MaterialGrid>
                        </>
                      ) : null}
                    </>
                  ) : null
                ) : null}
              </>
            ) : null}
            <MaterialGrid item xs={12}>
              <Field
                component={InputSwitchLarge}
                label={'Ocultar da área logada'}
                name="only_public"
                disabled={this.props.router.params.action === 'proposta'}
                required
                margin="normal"
              />
            </MaterialGrid>
            <MaterialGrid item xs={12}>
              <Field
                component={InputSwitchLarge}
                label={'Essa atividade estará em destaque para os participantes?'}
                name="featuredPrivate"
                required
                margin="normal"
              />
            </MaterialGrid>

            <MaterialGrid item xs={12}>
              <Field
                component={InputSwitchLarge}
                label={'Essa atividade estará em destaque para os visitantes?'}
                name="featuredPublic"
                required
                margin="normal"
              />
            </MaterialGrid>
            <MaterialGrid item xs={12}>
              <Field
                component={InputSwitchLarge}
                label={'Habilitar comentários e curtidas na atividade'}
                name="comments_and_likes_enabled"
                required
                margin="normal"
              />
            </MaterialGrid>
            <GenericConfirmationCard
              open={this.props.openConfirmation}
              cancelClose={this.props.handleCancelClose}
              labelLeftButton={'Continuar aqui'}
              labelRightButton={this.props.buttonConfirmationLabel}
              title={'Deseja sair sem publicar?'}
              text={'Ao sair sem publicar, as alterações do último passo não serão salvas.'}
              general={this.props.general}
              maxWidthFixed
              confirmButtonColor={'primary'}
              onClose={this.props.handleCancelClose}
              onClick={this.props.closeModal}
            />
          </div>
          <MaterialGrid
            container
            display="flex"
            justify="space-around"
            alignItems="center"
            style={{
              margin: '25px 0px',
              overflowX: 'hidden',
              paddingRight: isMobile ? 0 : 29,
            }}>
            <SecondaryButton
              type="button"
              color="secondary"
              onClick={this.props.prevClick}
              // progress={parseInt(this.props.general.submitProgress)}
              style={{
                margin: 0,
                width: '48%',
                padding: '10px 20px',
                textTransform: 'none',
              }}>
              Voltar
            </SecondaryButton>
            <PrimaryButton
              type="submit"
              color="primary"
              disabled={this.props.activeActions.buttonLoading}
              progress={parseInt(this.props.general.submitProgress)}
              onClick={() => this.setState({ submitted: true })}
              style={{
                margin: 0,
                width: '48%',
                padding: isMobile ? '12px 20px' : '10px 20px',
                textTransform: 'none',
                fontSize: isMobile ? 12 : undefined,
              }}>
              {this.props.activeActions.buttonLoading ? 'Publicando' : 'Publicar'}
            </PrimaryButton>
          </MaterialGrid>
        </Form>
      </>
    );
  }
}

Publish.propTypes = {
  activeActions: PropTypes.shape({
    buttonLoading: PropTypes.any,
  }),
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  buttonConfirmationLabel: PropTypes.any,
  closeModal: PropTypes.func,
  contacts: PropTypes.shape({
    list: PropTypes.shape({
      content: PropTypes.shape({
        filter: PropTypes.func,
      }),
    }),
  }),
  disabled: PropTypes.any,
  form: PropTypes.any,
  formValues: PropTypes.shape({
    from: PropTypes.any,
    hasPriority: PropTypes.any,
    has_target: PropTypes.any,
    is_event: PropTypes.any,
    segment_users: PropTypes.any,
    segmentation_type: PropTypes.string,
    to: PropTypes.any,
  }),
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  getContactsByStationIdSetForm: PropTypes.func,
  getPlansList: PropTypes.func,
  getStationActiveActions: PropTypes.func,
  handleCancelClose: PropTypes.any,
  handleSubmit: PropTypes.func,
  onClickProgress: PropTypes.func,
  openConfirmation: PropTypes.any,
  plans: PropTypes.shape({
    list: PropTypes.shape({
      content: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
  }),
  prevClick: PropTypes.any,
  requiredFieldError: PropTypes.func,
  router: PropTypes.shape({
    params: PropTypes.shape({
      action: PropTypes.string,
    }),
  }),
  submitErrors: PropTypes.any,
};

const mapStateToProps = (state, { form }) => {
  return {
    auth: state.auth,
    activeActions: state.activeActions,
    formValues: getFormValues(form)(state),
    form: form,
    general: state.general,
    contacts: state.contacts,
    submitErrors: getFormSyncErrors(form)(state),
    metaForm: getFormMeta(form)(state),
    plans: state.plans,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getStationActiveActions,
      getContactsByStationIdSetForm,
      requiredFieldError,
      getPlansList,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(Publish)
);
