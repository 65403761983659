const INITIAL_STATE = {
  loading: false,
  list: [],
  buttonLoading: false,
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ACTION_HISTORY_LOAD':
      return { ...state, loading: action.payload };
    case 'ACTION_HISTORY_FETCHED':
      return { ...state, list: action.payload, loading: false };
    case 'ACTION_HISTORY_BUTTON_LOAD':
      return { ...state, buttonLoading: action.payload };
    default:
      return state;
  }
};
