import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import moment from 'moment';
import types from './types';
import { calculateRemainingCash } from '../../../../../helpers/utils';

const URL_ACTION_PAGE_TABLE = `${BASE_API}/actions-list-table`;
const URL_DELETE_DRAFT = `${BASE_API}/draft-action`;

export const getStationActionsDrafts = (id) => {
  return (dispatch) => {
    dispatch({ type: 'DRAFT_ACTIONS_LOAD', payload: true });
    axios
      .get(
        `${URL_ACTION_PAGE_TABLE}/?where[active]=0&&where[is_draft]=1&&where[station_id]=${id}`,
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        for (const i in response.data) {
          response.data[i].createdAtDate = moment(response.data[i].createdAt).format(
            'DD/MM/YYYY HH:mm:ss'
          );
          response.data[i].createdDate = moment(response.data[i].createdAt).format(
            'DD/MM/YYYY HH:mm:ss'
          );
          response.data[i].createdDate = moment(response.data[i].createdAt).format(
            'DD/MM/YYYY HH:mm:ss'
          );
          response.data[i].totalSpendAction =
            parseFloat(response.data[i].limit) * parseFloat(response.data[i].points);
          response.data[i].currentSpendAction =
            parseFloat(response.data[i].userActions.length) * parseFloat(response.data[i].points);
          response.data[i].remainingCashAction = calculateRemainingCash(
            response.data[i].userActions.length,
            parseFloat(response.data[i].limit),
            parseInt(response.data[i].type_id) === 1 || parseInt(response.data[i].type_id) === 19
              ? parseFloat(response.data[i].member_get_members?.[0]?.points_inviter) +
                  parseFloat(response.data[i].member_get_members?.[0]?.points_invited)
              : parseFloat(response.data[i].points)
          );
        }
        dispatch([
          {
            type: 'DRAFT_ACTIONS_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const onClickDraftAction = (action, router = undefined) => {
  return (dispatch) => {
    if (action.type_id === 7 || action.type_id === 8 || action.type_id === 6) {
      if (!_.isUndefined(router)) {
        router.push(`/owner/acoes/aquisicao-clientes/pesquisa/${action.id}/rascunho`);
      }
    }
    if (action.type_id === 4) {
      if (!_.isUndefined(router)) {
        router.push(`/owner/acoes/curso-presencial/${action.id}/rascunho`);
      }
    } else if (action.type_id === 3) {
      router.push(`/owner/acoes/cupons/${action.id}/rascunho`);
    } else if (action.type_id === 9) {
      router.push(`/owner/acoes/checkList/${action.id}/rascunho`);
    } else if (action.type_id === 10) {
      router.push(`/owner/acoes/pollQuiz/${action.id}/rascunho`);
    } else if (action.type_id === 11) {
      router.push(`/owner/acoes/rightAnswer/${action.id}/rascunho`);
    } else if (action.type_id === 12) {
      router.push(`/owner/acoes/quiz-personalidade/${action.id}/rascunho`);
    } else if (action.type_id === 2) {
      router.push(`/owner/acoes/signup-earn/${action.id}/rascunho`);
    } else if (action.type_id === 1) {
      router.push(`/owner/acoes/member-get-member/${action.id}/rascunho`);
    } else if (action.type_id === 19) {
      router.push(`/owner/acoes/member-get-permission/${action.id}/rascunho`);
    } else if (action.type_id === 5) {
      router.push(`/owner/acoes/compartilhe/${action.id}/rascunho`);
    } else if (action.type_id === 13) {
      router.push(`/owner/acoes/redirecionar-link/${action.id}/rascunho`);
    } else if (action.type_id === 14) {
      router.push(`/owner/acoes/entregar-produto/${action.id}/rascunho`);
    } else if (action.type_id === 15) {
      router.push(`/owner/acoes/agreement/${action.id}/rascunho`);
    } else if (action.type_id === 16) {
      router.push(`/owner/acoes/conteudo-s-i/${action.id}/rascunho`);
    } else if (action.type_id === 17) {
      router.push(`/owner/acoes/atividade-doacao/${action.id}/rascunho`);
    } else if (action.type_id === 18) {
      router.push(`/owner/acoes/video-playlist/${action.id}/rascunho`);
    } else if (action.type_id === 20) {
      router.push(`/owner/acoes/redirecionar-link-area-interna/${action.id}/rascunho`);
    }
  };
};

export const deleteActionDraft = (id, setFinishDelete, setOpenConfirmation) => {
  return (dispatch) => {
    dispatch([
      { type: types.DELETE_TEMPLATE_BUTTON_LOAD, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
      setFinishDelete(false),
    ]);
    axios
      .delete(`${URL_DELETE_DRAFT}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          { type: types.DELETE_TEMPLATE_BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);
      })
      .catch((error) => {
        try {
          if (error.response.data.error) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `${error.response.data.error}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: `Erro interno do servidor`,
              variant: 'error',
              open: true,
            },
          });
        }
      })
      .finally(() => {
        dispatch({
          type: types.DELETE_TEMPLATE_BUTTON_LOAD,
          payload: false,
        });
        setFinishDelete(true);
        setOpenConfirmation(false);
      });
  };
};
