import { BASE_API, USER_TOKEN } from 'config/consts';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { reset as resetForm, initialize, change } from 'redux-form';
import { String2Currency } from '../../../../helpers/dataTransformers';

const URL_EARN_POINTS = `${BASE_API}/earn-points-transactions`;
const URL_CONTACT = `${BASE_API}/contacts`;
const URL_CONTACTS_BY_STATION_ID = `${BASE_API}/users-stations`;
const URL_POST_CSV = `${BASE_API}/contacts/csv`;
const URL_GET_CONTACTS = `${BASE_API}/contacts/establishment`;
const URL_ARRAY_CONTACTS = `${BASE_API}/contacts-array`;
const URL_INVITE_OWNER = `${BASE_API}/invite-to-establishment`;
const URL_USER_SUPPORT = `${BASE_API}/user-support-messages`;
const URL_REMOVE_ACCESS = `${BASE_API}/remove-user-access`;

export const postCsvFileToContacts = (data, establishment, router = undefined, first_time) => {
  return (dispatch) => {
    dispatch({
      type: 'CONTACTS_LOAD',
      payload: true,
    });
    axios
      .post(
        `${URL_POST_CSV}/${establishment}`,
        { array: data },
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        if (response.data.error?.length > 0) {
          dispatch([
            {
              type: 'CONTACT_IMPORT_HAS_ERROR',
              payload: true,
            },
            {
              type: 'CONTACT_IMPORT_ERRORS',
              payload: response.data.error,
            },
          ]);
          if (!_.isUndefined(router)) {
            if (first_time) {
              router.push('/owner/contacts/?first_time=true');
            } else {
              router.push('/owner/contacts');
            }
          }
        } else {
          dispatch({
            type: 'CONTACTS_STATUS',
            payload: true,
          });
          if (!_.isUndefined(router)) {
            if (first_time) {
              router.push('/owner/contacts/credenciados/?first_time=true');
            } else {
              router.push('/owner/contacts/credenciados');
            }
          }
        }
      });
  };
};

export const getContactById = (id) => {
  return (dispatch) => {
    dispatch({
      type: 'CONTACT_DETAIL_LOAD',
      payload: true,
    });
    axios
      .get(`${URL_CONTACT}/?where[id]=${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        if (!_.isUndefined(response.data[0]) && !_.isNull(response.data[0])) {
          for (const i in response.data[0].activities) {
            response.data[0].activities[i].createdAt = moment(
              response.data[0].activities[i].createdAt
            ).format('DD/MM/YYYY HH:mm:ss');
          }
        }
        dispatch([
          {
            type: 'CONTACT_DETAIL_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const removeAccess = (id, resolve, reject, other = undefined, setOpenConfirmation) => {
  return (dispatch) => {
    dispatch({ type: 'CONTACT_DETAIL_LOAD', payload: false });
    axios
      .delete(`${URL_REMOVE_ACCESS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          {
            type: 'CONTACT_DETAIL_LOAD',
            payload: false,
          },

          // resolve(),
          setOpenConfirmation(false),
          getContactsByStationId(other),
        ]);
      })
      .catch((e) => {
        try {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: `Erro: ${e.response.data.message}`,
              variant: 'error',
              open: true,
            },
          });
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
        // reject();
      });
  };
};

export const getContactsByStationId = (id) => {
  return (dispatch) => {
    dispatch({
      type: 'CONTACTS_LOAD',
      payload: true,
    });
    axios
      .get(`${URL_CONTACTS_BY_STATION_ID}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        let newResponse = response.data;
        if (response.data.length > 0) {
          if (!_.isUndefined(response.data[0].user) && !_.isNull(response.data[0].user)) {
            for (const i in response.data[0].user.activities) {
              response.data[0].user.activities[i].createdAtDate = moment(
                response.data[0].user.activities[i].createdAt
              ).format('DD/MM/YYYY HH:mm:ss');
            }
          }

          newResponse = response.data.map((item, index) => {
            return {
              ...item,
              isFiliated: item.filiated ? 'Sim' : 'Não',
              isInternal: item.internal ? 'Sim' : 'Não',
            };
          });
        }
        dispatch([
          {
            type: 'CONTACTS_FETCHED',
            payload: newResponse,
          },
        ]);
      });
  };
};

const changeUsers = (values, form) => {
  return (dispatch) => {
    dispatch(change(form, 'segment_users', values));
  };
};

export const getContactsByStationIdSetForm = (id, segment_users_ids, form) => {
  return (dispatch) => {
    dispatch({
      type: 'CONTACTS_LOAD',
      payload: true,
    });
    axios
      .get(`${URL_CONTACTS_BY_STATION_ID}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        let newResponse = response.data;
        if (response.data.length > 0) {
          if (!_.isUndefined(response.data[0].user) && !_.isNull(response.data[0].user)) {
            for (const i in response.data[0].user.activities) {
              response.data[0].user.activities[i].createdAtDate = moment(
                response.data[0].user.activities[i].createdAt
              ).format('DD/MM/YYYY HH:mm:ss');
            }
          }

          newResponse = response.data.map((item, index) => {
            return {
              ...item,
              isFiliated: item.filiated ? 'Sim' : 'Não',
              isInternal: item.internal ? 'Sim' : 'Não',
            };
          });
        }
        const filteredIds = segment_users_ids.map((item) => {
          if (item) {
            const list = newResponse.filter((itemFilter) => itemFilter.id === item);

            const final = {
              id: list?.[0]?.id,
              title: list?.[0]?.email,
            };
            return final;
          }
          return item;
        });
        dispatch([
          {
            type: 'CONTACTS_FETCHED',
            payload: newResponse,
          },
          changeUsers(filteredIds, form),
        ]);
      });
  };
};

export const dispatchErrorFalse = () => {
  return (dispatch) => {
    dispatch([
      {
        type: 'CONTACT_IMPORT_HAS_ERROR',
        payload: false,
      },
    ]);
  };
};

export const postContacts = (values, router = undefined, first_time) => {
  return (dispatch) => {
    dispatch([
      { type: 'CONTACT_BUTTON_LOAD', payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);

    axios
      .post(`${URL_ARRAY_CONTACTS}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          { type: 'CONTACT_BUTTON_LOAD', payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);
        if (!_.isUndefined(router)) {
          if (first_time) {
            router.push('/owner/contacts/credenciados/?first_time=true');
          } else {
            router.push('/owner/contacts/credenciados/');
          }
        }
      })
      .catch((e) => {
        dispatch([
          { type: 'CONTACT_BUTTON_LOAD', payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);

        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getContactByEstablishmentId = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: 'CONTACTS_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${URL_GET_CONTACTS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        for (const i in response.data) {
          response.data[i].createdAtDate = moment(response.data[i].createdAt).format(
            'DD/MM/YYYY HH:mm:ss'
          );
          for (const attribute in response.data[i]) {
            if (response.data[i][attribute] === null) {
              response.data[i][attribute] = 'Não informado';
            } else if (response.data[i][attribute] === true) {
              response.data[i][attribute] = 'Sim';
            } else if (!response.data[i][attribute]) {
              response.data[i][attribute] = 'Não';
            }
          }
        }

        dispatch([
          {
            type: 'CONTACTS_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        // console.log(e);
      });
  };
};

export const getEarnPointsTransactions = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: 'CONTACT_EARN_POINTS_TRANSACTIONS_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${URL_EARN_POINTS}/?where[user_id]=${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        const credits = response.data.filter((item) => item.points > 0);
        for (const i in credits) {
          credits[i].createdAtDate = moment(credits[i].createdAt).format('DD/MM/YYYY HH:mm:ss');
          credits[i].points = String2Currency(credits[i].points);
        }
        dispatch([
          {
            type: 'CONTACT_EARN_POINTS_TRANSACTIONS_FETCHED',
            payload: credits,
          },
        ]);
      })
      .catch((e) => {
        // console.log(e);
      });
  };
};

export const inviteOwner = (values) => {
  return (dispatch) => {
    dispatch([
      { type: 'BUTTON_LOAD', payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);

    axios
      .post(`${URL_INVITE_OWNER}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          reset(),
        ]);
      })
      .catch((e) => {
        try {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: `Erro: ${e.response.data.messages[0]}`,
              variant: 'error',
              open: true,
            },
          });
        } catch {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: `Erro`, variant: 'error', open: true },
          });
        }
        console.log(e);
      })
      .finally(() => {
        dispatch([
          { type: 'BUTTON_LOAD', payload: false },
          { type: 'SUBMIT_PROGRESS', payload: 0 },
        ]);
      });
  };
};

export const remove = (id, station_id) => {
  return (dispatch) => {
    axios
      .delete(`${URL_CONTACT}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([getContactByEstablishmentId(station_id)]);
      });
  };
};

export const setContactFormValues = (values) => {
  return (dispatch) => {
    dispatch(initializeForm(values));
  };
};

export const initializeForm = (values) => {
  return initialize('editContactForm', values);
};

export const editPreRegister = (values, id) => {
  return (dispatch) => {
    dispatch([
      { type: 'CONTACT_BUTTON_LOAD', payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .put(`${BASE_API}/contacts/${id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: `Alteração realizada com sucesso`,
              variant: 'success',
              open: true,
            },
          },
        ]);
        window.location.reload();
      })
      .catch((e) => {
        dispatch([
          { type: 'CONTACT_BUTTON_LOAD', payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);

        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const reset = () => {
  return resetForm('InviteOwnerForm');
};

export const getUserSupportMessages = (id) => {
  return (dispatch) => {
    dispatch([{ type: 'CONTACT_SUPPORT_MESSAGES_LOAD', payload: true }]);
    axios
      .get(`${URL_USER_SUPPORT}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        for (const message of response.data) {
          message.createdAt = moment(message.createdAt).format('DD/MM/YYYY HH:mm');
        }
        dispatch([
          {
            type: 'CONTACT_SUPPORT_MESSAGES_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        dispatch([{ type: 'CONTACT_SUPPORT_MESSAGES_LOAD', payload: false }]);
        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};
