import PropTypes from 'prop-types';
import { Backdrop, Box, Button, CircularProgress, Grid } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import ConfirmationCard from 'common/components/cards/confirmationCard/confirmationCard';
import GenericConfirmationCard from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import MaterialCard from 'common/components/cards/materialCard';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table from 'common/components/table/table-material';
import {
  ActionNameElement,
  CashElement,
  ColumnsBelowSearch,
  DateElement,
  NumberGenericElement,
  RemainCashElement,
} from 'common/components/table/tableComponents';
import 'common/components/table/tableGeneral.css';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Engagement from '../../../../../assets/titlesMobile/engagement.svg';
import { BASE_S3 } from '../../../../../config/consts';
import {
  deleteActionDraft,
  getStationActionsDrafts,
  onClickDraftAction,
} from '../actionsDrafts/actionsDraftsScripts';
import { getTemplates } from '../actionsFile';
import {
  deleteActionSuspended,
  getStationHistory,
  reactivateAction,
} from '../actionsHistory/actionsHistoryActions';
import ReactivateAction from '../actionsHistory/reactivateForm';
import {
  duplicateAction,
  getStationActiveActions,
  onClickAction,
  onClickActionResults,
  onClickActionShare,
  suspendActivity,
} from '../activeActions/activeActionsScripts';
import { VouchersEmLoteModal } from 'modules/owner/pages/vouchers';

const defaultFilters = [
  {
    label: 'Número Máximo de Participantes',
    field: 'limit',
    component: 'slider',
    minValue: 0,
    maxValue: 100,
    typeData: 'quantity',
  },
  {
    label: 'Gasto',
    field: 'currentSpendAction',
    component: 'slider',
    typeData: 'money',
    minValue: 0,
    maxValue: 200,
  },
  {
    label: 'CashBack restante',
    field: 'remainingCashAction',
    component: 'slider',
    typeData: 'money',
    minValue: 0,
    maxValue: 100,
  },
  {
    label: 'Filtrar entre as datas:',
    field: 'betweenDates',
    component: 'datePicker',
    typeData: 'date',
    filterFunction: 0,
    startDate: new Date(),
    endDate: new Date(),
  },
];

function GeneralActions(props) {
  const { auth } = props;
  const contentActive = props.activeActions.list;
  const contentDrafts = props.actionsDrafts.list;
  const contentHistory = props.actionsHistory.list;

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openDuplicateBackDrop, setOpenDuplicateBackDrop] = useState(false);
  const [actionData, setActionData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [item, setItem] = React.useState(null);
  const [itemHistory, setItemHistory] = React.useState(null);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [template, setTemplate] = useState({});
  const [finishDelete, setFinishDelete] = useState(false);
  const [openModalLote, setOpenModalLote] = useState(false);
  const [selectedRow, setSelectedRow] = useState();

  const [selectedOption, setSelectedOption] = useState(
    props.location.query.to ? props.location.query.to : 'online'
  );
  const [filters, setFilters] = useState(defaultFilters);

  const getMaxValue = (array, key) => {
    const arrayNumbers = array.map((item) => parseFloat(item[key]));
    return arrayNumbers.reduce((max, number) => (number > max ? number : max), arrayNumbers[0]);
  };

  useEffect(() => {
    setFilters((prevState) => {
      const array = [...prevState];
      array.forEach((item, index) => {
        if (item.maxValue) item.maxValue = getMaxValue(data, item.field);
      });
      return array;
    });
  }, [data]);

  const defaultColumnsMobile = [
    {
      title: '',
      field: 'action.name',
      cellStyle: {
        padding: 0,
        whiteSpace: 'nowrap',
      },
      render: (rowData) => (
        <ActionNameElement singleColum={false} actionName={rowData.name} image={rowData.image} />
      ),
    },
    {
      field: 'actions',
      sorting: false,
      headerStyle: {
        fontWeight: 500,
        fontSize: 13,
        color: 'blue',
        whiteSpace: 'nowrap',
      },
      cellStyle: {
        whiteSpace: 'nowrap',
        border: 0,
        padding: 0,
      },
      title: '',
      render: (rowData) => {
        return (
          <ColumnsBelowSearch
            buttons={
              selectedOption === 'online'
                ? {
                    iconButtons: [
                      {
                        name: 'options',
                        onClick: () => {},
                        menuIconOptions: [
                          {
                            label: 'Editar',
                            onClickMenuItem: () => {
                              props.onClickAction(rowData, props.router, 'online');
                            },
                          },
                          {
                            label: 'Encerrar',
                            onClickMenuItem: () => {
                              props.suspendActivity(rowData.id, props.router, true);
                            },
                          },
                          {
                            label: 'Ver resultados',
                            onClickMenuItem: () => {
                              props.onClickActionResults(rowData, props.router, 'online');
                            },
                          },
                        ],
                      },
                    ],
                  }
                : selectedOption === 'drafts'
                ? {
                    namedButtons: [],
                    iconButtons: [
                      {
                        name: 'delete',
                        onClick: () => {
                          setOpenConfirmation(true);
                          setItem(rowData.id);
                        },
                        color: '#E34848',
                      },
                    ],
                  }
                : selectedOption === 'scheduled'
                ? {
                    namedButtons: [],
                    iconButtons: [
                      {
                        name: 'options',
                        onClick: () => {},
                        menuIconOptions: [
                          {
                            label: 'Editar',
                            onClickMenuItem: () => {
                              props.onClickAction(rowData, props.router, 'scheduled');
                            },
                          },
                          {
                            label: 'Encerrar',
                            onClickMenuItem: () => {
                              props.suspendActivity(rowData.id, props.router);
                            },
                          },
                        ],
                      },
                    ],
                  }
                : selectedOption === 'closed'
                ? {
                    namedButtons: [],
                    iconButtons: [
                      {
                        name: 'delete',
                        onClick: () => {
                          setOpenConfirmation(true);
                          setItemHistory(rowData.id);
                        },
                        color: '#E34848',
                      },
                    ],
                  }
                : null
            }
          />
        );
      },
    },
  ];

  const defaultColumns = [
    {
      title: 'Nome da atividade',
      field: 'name',
      cellStyle: {
        padding: 0,
        whiteSpace: 'nowrap',
        border: '1px solid black',
      },
      render: (rowData) => <ActionNameElement actionName={rowData.name} image={rowData.image} />,
    },
    {
      title: 'Início da publicação',
      field: 'participationStart',
      sorting: false,
      render: (rowData) => <DateElement date={rowData.startDate} />,
    },
    {
      title: 'Fim da publicação',
      field: 'participationEnd',
      sorting: false,
      render: (rowData) => <DateElement date={rowData.endDate} />,
    },
    /* {
      title: 'Participantes',
      field: 'userActions.length',
      sorting: false,
      render: (rowData) => (
        <NumberGenericElement
          category="participantes"
          divider
          valueSec={rowData.limit}
          value={rowData.userActions.length}
        />
      ),
    }, */
    {
      title: 'Gasto',
      field: 'currentSpendAction',
      sorting: false,
      render: (rowData) => (
        <CashElement
          value={rowData.currentSpendAction}
          divider={false}
          valueSec={rowData.totalSpendAction}
        />
      ),
    },

    {
      title: 'Créditos restantes',
      field: 'remainingCashAction',
      sorting: false,
      render: (rowData) => <RemainCashElement value={rowData.remainingCashAction} />,
    },
    {
      field: 'actions',
      sorting: false,
      headerStyle: {
        fontWeight: 500,
        fontSize: 13,
        color: 'blue',
        whiteSpace: 'nowrap',
      },
      cellStyle: {
        whiteSpace: 'nowrap',
        border: 0,
        padding: 0,
      },
      title: '',
      render: (rowData) => {
        const onlineItems = [
          {
            label: 'Editar',
            onClickMenuItem: () => {
              props.onClickAction(rowData, props.router, 'online');
            },
          },
          {
            label: 'Encerrar',
            onClickMenuItem: () => {
              props.suspendActivity(rowData.id, props.router, true);
            },
          },
          {
            hasOnClick: true,
            label: 'Divulgar',
            onClickMenuItem: () => {
              props.onClickActionShare(rowData, props.router);
            },
          },
          {
            hasOnClick: true,
            label: 'Ver Resultados',
            onClickMenuItem: () => {
              props.onClickActionResults(rowData, props.router, 'online');
            },
          },
          {
            hasOnClick: true,
            label: 'Gerar Vouchers em Lote',
            onClickMenuItem: () => {
              setSelectedRow(rowData);
              setOpenModalLote(true);
            },
          },
        ];
        const draftsItems = [
          {
            label: 'Deletar',
            onClickMenuItem: () => {
              setOpenConfirmation(true);
              setItem(rowData.id);
            },
          },
        ];

        const suspendedItems = [
          {
            label: 'Ver Resultados',
            onClickMenuItem: () => {
              props.onClickActionResults(rowData, props.router, 'closed');
            },
          },
          {
            label: 'Reativar',
            onClickMenuItem: () => {
              setActionData(rowData);
              setVisible(true);
            },
          },
          {
            label: 'Excluir',
            onClickMenuItem: () => {
              setOpenConfirmation(true);
              setItemHistory(rowData.id);
            },
          },
        ];
        if (rowData.type_id === 3 || rowData.type_id === 4 || rowData.type_id === 16) {
          draftsItems.push({
            label: 'Duplicar',
            onClickMenuItem: () =>
              props.duplicateAction(rowData, props.router, setOpenDuplicateBackDrop),
          });
          onlineItems.push({
            hasOnClick: true,
            label: 'Duplicar',
            onClickMenuItem: () => {
              props.duplicateAction(rowData, props.router, setOpenDuplicateBackDrop);
            },
          });
          suspendedItems.push({
            hasOnClick: true,
            label: 'Duplicar',
            onClickMenuItem: () => {
              props.duplicateAction(rowData, props.router, setOpenDuplicateBackDrop);
            },
          });
        }
        return (
          <ColumnsBelowSearch
            buttons={
              selectedOption === 'online'
                ? {
                    namedButtons: [],
                    iconButtons: [
                      {
                        hasOnClick: true,
                        name: 'options',
                        onClick: () => {},
                        menuIconOptions: onlineItems,
                      },
                    ],
                  }
                : selectedOption === 'drafts'
                ? {
                    namedButtons: [
                      {
                        name: 'Continuar edição',
                        onClick: () => props.onClickDraftAction(rowData, props.router),
                      },
                    ],
                    iconButtons: [
                      {
                        hasOnClick: true,
                        name: 'options',
                        onClick: () => {},
                        menuIconOptions: draftsItems,
                      },
                    ],
                  }
                : selectedOption === 'scheduled'
                ? {
                    namedButtons: [
                      {
                        name: 'Ativar Agora',
                        onClick: () => {},
                      },
                    ],
                    iconButtons: [
                      {
                        name: 'options',
                        onClick: () => {},
                        menuIconOptions: [
                          {
                            label: 'Editar',
                            onClickMenuItem: () => {
                              props.onClickAction(rowData, props.router, 'scheduled');
                            },
                          },
                          {
                            label: 'Encerrar',
                            onClickMenuItem: () => {
                              props.suspendActivity(rowData.id, props.router);
                            },
                          },
                        ],
                      },
                    ],
                  }
                : selectedOption === 'closed'
                ? {
                    iconButtons: [
                      {
                        hasOnClick: true,
                        name: 'options',
                        onClick: () => {},
                        menuIconOptions: suspendedItems,
                      },
                    ],
                  }
                : null
            }
          />
        );
      },
    },
  ];
  let filteredDefaultColums;
  if (selectedOption === 'closed') {
    filteredDefaultColums = isMobile
      ? defaultColumnsMobile
      : defaultColumns.filter((item) => item.field !== 'remainCash');
  } else {
    filteredDefaultColums = isMobile ? defaultColumnsMobile : defaultColumns;
  }
  const [columns, setColumns] = useState(filteredDefaultColums);
  useEffect(() => {
    setColumns(filteredDefaultColums);
  }, [selectedOption]);

  const options = [
    { label: 'Online', value: 'online' },
    { label: 'Programadas', value: 'scheduled' },
    { label: 'Rascunho', value: 'drafts' },
    { label: 'Encerradas', value: 'closed' },
  ];

  const optionsMobile = [
    { label: 'Atividades online', value: 'online' },
    { label: 'Atividades programadas', value: 'scheduled' },
    { label: 'Rascunhos', value: 'drafts' },
    { label: 'Atividades encerradas', value: 'closed' },
  ];

  const getActionsStatus = (activity) => {
    if (activity.active && !activity.is_draft) {
      if (moment(new Date()).isBetween(activity.start, activity.end)) return 'online';
      if (moment(new Date()).isBefore(activity.start)) return 'scheduled';
    }
    return 'other';
  };

  useEffect(() => {
    if (selectedOption === 'drafts') {
      props.getStationActionsDrafts(auth.user.establishments[0].stations[0].id);
    }
    if (selectedOption === 'online' || selectedOption === 'scheduled') {
      props.getStationActiveActions(auth.user.establishments[0].stations[0].id);
    }
    if (selectedOption === 'closed') {
      props.getStationHistory(auth.user.establishments[0].stations[0].id);
    }

    props.getTemplates(auth.user.establishments[0].id);
  }, [selectedOption]);

  useEffect(() => {
    if (finishDelete) {
      setItem(null);
      setItemHistory(null);

      if (selectedOption === 'drafts') {
        props.getStationActionsDrafts(auth.user.establishments[0].stations[0].id);
      }
      if (selectedOption === 'online' || selectedOption === 'scheduled') {
        props.getStationActiveActions(auth.user.establishments[0].stations[0].id);
      }
      if (selectedOption === 'closed') {
        props.getStationHistory(auth.user.establishments[0].stations[0].id);
      }

      props.getTemplates(auth.user.establishments[0].id);
    }
  }, [finishDelete]);

  useEffect(() => {
    if (selectedOption === 'closed') {
      setData(props.actionsHistory.list.slice(0).reverse());
    }
    if (selectedOption === 'drafts') {
      setData(props.actionsDrafts.list);
    }
    if (selectedOption === 'online') {
      const onlineFiltered = props.activeActions.list.filter(
        (action) => getActionsStatus(action) === 'online'
      );
      setData(onlineFiltered);
    }
    if (selectedOption === 'scheduled') {
      const scheduleFiltered = props.activeActions.list.filter(
        (action) => getActionsStatus(action) === 'scheduled'
      );
      setData(scheduleFiltered);
    }
  }, [props.activeActions.list, props.actionsDrafts.list, props.actionsHistory.list]);

  function loadData(option) {
    setSelectedOption(option);
  }

  function handleClose() {
    setVisible(false);
  }

  const renderTemplatesList = () => {
    if (props.actions.templatesLoading) {
      return (
        <Box display="flex" width="100vw" justifyContent="center" alignContent="center" mt={7}>
          <LoadingProgress />
        </Box>
      );
    }
    const templatesBase = props.actions?.templatesList?.filter(
      (item) => parseInt(item.category) === 1
    );

    if (templatesBase.length > 0) {
      return templatesBase.map((item, index) => {
        return (
          <GeneralGrid item xs={12} md={3} key={index} style={{ marginBottom: 10 }}>
            <MaterialCard
              horizontalCard
              onClick={
                item.has_bought
                  ? () => {
                      setOpen(true);
                      setTemplate(item);
                    }
                  : () => props.router.push(`/owner/acoes/${item.id}`)
              }
              name={item.name}
              has_bought={item.has_bought}
              image={`${BASE_S3}/${item.owner_image || item.image}`}
              buttonLabel={item.has_bought ? 'Ativar' : 'Veja Mais'}
              type={item.type.name}
            />
          </GeneralGrid>
        );
      });
    } else {
      return null;
    }
  };

  function renderEmptyScreen() {
    return (
      <section id="activeActions">
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">Você ainda não possui Atividades Aprovadas</h2>
                  <p className="">
                    Atividades são campanhas que você poderá distribuir para seus associados.
                  </p>

                  <PrimaryButton onClick={() => props.router.push('/owner/acoes')} color="primary">
                    Criar Atividade
                  </PrimaryButton>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img src={placeholder_illustration} className="img-fluid" alt="Ilustração Cupons" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (
    contentActive.length < 1 &&
    !props.activeActions.loading &&
    contentDrafts.length < 1 &&
    !props.activeActions.loading &&
    contentHistory.length < 1 &&
    !props.activeActions.loading &&
    props.actions.templatesList.length < 1 &&
    !props.actions.templatesLoading
  ) {
    return renderEmptyScreen();
  }

  const renderEmptyCard = () => {
    return (
      <GeneralGrid item xs={12} md={3}>
        <MaterialCard
          horizontalCard
          router={props.router}
          cardEmpty
          onClick={() => {}}
          name={'Abrir loja de atividades'}
          image={``}
          type={''}
        />
      </GeneralGrid>
    );
  };

  return (
    <Grid
      container
      justifyContent="center"
      xs={12}
      style={{
        maxWidth: '86vw',
        paddingBottom: '4rem',
      }}>
      <GenericConfirmationCard
        open={openConfirmation}
        loading={props.actionsDrafts.buttonLoading}
        cancelClose={() => setOpenConfirmation(false)}
        general={props.general}
        title={`${item ? 'Deletar rascunho' : 'Deletar atividade'}`}
        confirmButtonColor={'primary'}
        text={`${
          item
            ? `Tem certeza que deseja deletar esse rascunho`
            : `Tem certeza que deseja deletar essa atividade`
        }? Essa ação não poderá ser desfeita`}
        onClose={() => setOpenConfirmation(false)}
        onClick={() => {
          if (item) props.deleteActionDraft(item, setFinishDelete, setOpenConfirmation);
          if (itemHistory) {
            props.deleteActionSuspended(itemHistory, setFinishDelete, setOpenConfirmation);
          }
        }}
      />
      <GeneralGrid
        container
        justifyContent="center"
        spacing={2}
        style={{
          display: 'flex',
          marginBottom: 15,
          marginTop: 15,
        }}>
        {isMobile ? (
          <div className="d-flex justify-content-start align-items-center container-fluid">
            <div style={{ width: 23, height: 23 }}>
              <img src={Engagement} alt="engagement" style={{ width: 23, height: 'auto' }} />
            </div>
            <h4
              style={{
                fontSize: 20,
                fontWeight: 400,
                margin: 0,
                marginLeft: 15,
              }}>
              Engajamento
            </h4>
          </div>
        ) : null}
      </GeneralGrid>
      <GeneralGrid
        container
        spacing={2}
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          overflowX: 'scroll',
          overflowY: 'hidden',
          marginBottom: '.7rem',
        }}>
        {isMobile ? null : renderEmptyCard()}
        {renderTemplatesList()}
      </GeneralGrid>

      <GeneralGrid
        container
        spacing={2}
        style={{
          width: isMobile ? '89vw' : '96vw',
          marginTop: isMobile ? 0 : 15,
        }}>
        {props.activeActions.loading ||
        props.actionsDrafts.loading ||
        props.actionsHistory.loading ? (
          <Box display="flex" height="100vh" width="100vw" justifyContent="center" mt={7}>
            <LoadingProgress />
          </Box>
        ) : (
          <Table
            filters={filters}
            searchPlaceholder={'Pesquisar por nome'}
            hasToolbar={true}
            defaultSelected={selectedOption}
            title="Atividades"
            columns={columns}
            rows={data}
            toolbarSearch
            onChangeOption={loadData}
            mobileTable={isMobile}
            mobileDropdown
            selectOptions={isMobile ? optionsMobile : options}
            hasBorder={!isMobile}
          />
        )}
      </GeneralGrid>
      <ReactivateAction
        visible={visible}
        onClose={handleClose}
        router={props.router}
        actionData={actionData}
      />
      <ConfirmationCard
        onClose={() => setOpen(false)}
        open={open}
        item={template.id}
        station_id={props.auth.user.establishments[0].stations[0].id}
        partner_id={null}
        sponsored={null}
        router={props.router}
      />
      <VouchersEmLoteModal
        atividade={selectedRow}
        visible={openModalLote}
        onClose={() => setOpenModalLote(false)}
      />
      <Backdrop
        style={{
          zIndex: 1500 + 1,
          color: '#fff',
        }}
        open={openDuplicateBackDrop}>
        <CircularProgress color="inherit" />{' '}
        <span style={{ marginLeft: 10 }}>Gerando duplicação da atividade...</span>
      </Backdrop>
    </Grid>
  );
}

GeneralActions.propTypes = {
  actions: PropTypes.shape({
    templatesList: PropTypes.shape({
      filter: PropTypes.func,
      length: PropTypes.number,
    }),
    templatesLoading: PropTypes.any,
  }),
  actionsDrafts: PropTypes.shape({
    buttonLoading: PropTypes.any,
    list: PropTypes.shape({
      length: PropTypes.number,
    }),
    loading: PropTypes.any,
  }),
  actionsHistory: PropTypes.shape({
    list: PropTypes.shape({
      length: PropTypes.number,
      slice: PropTypes.func,
    }),
    loading: PropTypes.any,
  }),
  activeActions: PropTypes.shape({
    list: PropTypes.shape({
      filter: PropTypes.func,
      length: PropTypes.number,
    }),
    loading: PropTypes.any,
  }),
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  deleteActionDraft: PropTypes.func,
  deleteActionSuspended: PropTypes.func,
  duplicateAction: PropTypes.func,
  general: PropTypes.any,
  getStationActionsDrafts: PropTypes.func,
  getStationActiveActions: PropTypes.func,
  getStationHistory: PropTypes.func,
  getTemplates: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      to: PropTypes.any,
    }),
  }),
  onClickAction: PropTypes.func,
  onClickActionResults: PropTypes.func,
  onClickActionShare: PropTypes.func,
  onClickDraftAction: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
  suspendActivity: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    activeActions: state.activeActions,
    actionsHistory: state.actionsHistory,
    actionsDrafts: state.actionsDrafts,
    auth: state.auth,
    actions: state.actions,
    general: state.general,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getStationActiveActions,
      getStationActionsDrafts,
      onClickActionResults,
      onClickActionShare,
      onClickAction,
      suspendActivity,
      onClickDraftAction,
      deleteActionDraft,
      getTemplates,
      getStationHistory,
      reactivateAction,
      deleteActionSuspended,
      duplicateAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralActions);
