import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import moment from 'moment';
import { reset as resetForm, destroy } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import types from './types';

const URL_ACTION_PAGE = `${BASE_API}/archived-actions`;
const URL_REACTIVATE = `${BASE_API}/actions/reactivate`;
const URL_DELETE_SUSPENDED = `${BASE_API}/suspended-action`;

export const getStationHistory = (id) => {
  return (dispatch) => {
    dispatch({ type: 'ACTION_HISTORY_LOAD', payload: true });
    axios
      .get(`${URL_ACTION_PAGE}/?where[station_id]=${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        for (const i in response.data) {
          response.data[i].createdAtDate = moment(response.data[i].createdAt).format(
            'DD/MM/YYYY HH:mm:ss'
          );
          response.data[i].startDate = moment(response.data[i].start).format('DD/MM/YYYY HH:mm');
          response.data[i].endDate = moment(response.data[i].end).format('DD/MM/YYYY HH:mm');
          response.data[i].participationStartDate = moment(
            response.data[i].participationStart
          ).format('DD/MM/YYYY HH:mm');
          response.data[i].participationEndDate = moment(response.data[i].participationEnd).format(
            'DD/MM/YYYY HH:mm'
          );
          response.data[i].totalSpendAction =
            parseFloat(response.data[i].limit) * parseFloat(response.data[i].points);

          let currentSpendAction = 0;
          for (const transaction of response.data[i].earn_points_transactions) {
            if (transaction.points) {
              currentSpendAction += parseFloat(transaction.points);
            }
          }
          response.data[i].currentSpendAction = currentSpendAction;
        }
        dispatch([
          {
            type: 'ACTION_HISTORY_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const reset = (form) => {
  return resetForm(form);
};

export const reactivateAction = (id, values, router, onClose) => {
  return (dispatch) => {
    dispatch({ type: 'ACTION_HISTORY_BUTTON_LOAD', payload: true });
    axios
      .put(`${URL_REACTIVATE}/${id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(() => {
        dispatch({ type: 'ACTION_HISTORY_BUTTON_LOAD', payload: false }, destroy('reactivateForm'));
        router.push('/owner/acoes/minhas-atividades/');
        toastr.success('Sucesso!', 'Atividade reativada!');
        window.location.reload();
        // onClose();
      })
      .catch((e) => {
        dispatch({ type: 'ACTION_HISTORY_BUTTON_LOAD', payload: false });
        try {
          for (const i in e.response.data) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `${e.response.data[i]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          onClose();
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const deleteActionSuspended = (id, setFinishDelete, setOpenConfirmation) => {
  return (dispatch) => {
    dispatch([
      { type: types.DELETE_TEMPLATE_BUTTON_LOAD, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
      setFinishDelete(false),
    ]);
    axios
      .delete(`${URL_DELETE_SUSPENDED}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          { type: types.DELETE_TEMPLATE_BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);
      })
      .catch((error) => {
        try {
          if (error.response.data.error) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `${error.response.data.error}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: `Erro interno do servidor`,
              variant: 'error',
              open: true,
            },
          });
        }
      })
      .finally(() => {
        dispatch({
          type: types.DELETE_TEMPLATE_BUTTON_LOAD,
          payload: false,
        });
        setFinishDelete(true);
        setOpenConfirmation(false);
      });
  };
};
