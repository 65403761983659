import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import PrimaryButton from 'common/components/buttons/primaryButton';
import LoadingProgress from 'common/components/progress/loading';
import Table from 'common/components/table/tableGeneral';

import {
  getActionDetail,
  getSegmentedUsers,
  sendTestMail,
  sendPushParticipants,
} from 'modules/owner/pages/actions/activeActions/activeActionsScripts.js';
import React from 'react';
import { FontIcon } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import PushSimulator from 'common/components/pushSimulator';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FORM_RULES } from 'helpers/validations';

class ShareToParticipants extends React.Component {
  constructor(props) {
    super(props);
    this.props.getSegmentedUsers(
      this.props.action.id,
      this.props.auth.user.establishments[0].stations[0].id
    );
    this.state = {
      open: false,
      values: {},
      columns: [
        {
          field: 'name',
          title: 'Nome',
        },
        { field: 'email', title: 'E-mail' },
      ],
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onSubmit = (values) => {
    this.setState({ open: true, values });
  };

  handleSendPush = () => {
    this.props.sendPushParticipants(this.state.values, this.props.action.id);
    this.handleClose();
  };

  render() {
    if (this.props.activeActions.loadingSegmentedShare) {
      return (
        <Box display="flex" height="100vh" justifyContent="center" mt={7}>
          <LoadingProgress />
        </Box>
      );
    }
    return (
      <MaterialGrid container spacing={8}>
        <MaterialGrid item xs={6} style={{ borderRight: '1px solid lightgray' }}>
          <Typography style={{ textAlign: 'left', fontSize: 12, color: 'gray' }}>
            Pré-visualização
          </Typography>
          <MaterialGrid container spacing={8}>
            <MaterialGrid
              item
              xs={12}
              style={{
                borderRight: '1px solid lightgray',
                display: 'flex',
                justifyContent: 'center',
              }}>
              <PushSimulator
                title={
                  this.props.formValues
                    ? this.props.formValues.title || 'Título da Push'
                    : 'Título da Push'
                }
                body={
                  this.props.formValues
                    ? this.props.formValues.body ||
                      'Texto da push: aqui você pode detalhar melhor a sua notificação'
                    : 'Texto da push: aqui você pode detalhar melhor a sua notificação'
                }
              />
            </MaterialGrid>
          </MaterialGrid>
        </MaterialGrid>
        <MaterialGrid item xs={6}>
          <MaterialGrid item xs={12}>
            <Form role="form" onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate>
              <MaterialGrid item xs={12}>
                <Box display="flex" justifyContent="flex-start" alignItems="center">
                  <Typography component="p">
                    {this.props.activeActions.segmentedShare.length}
                    {this.props.activeActions.segmentedShare.length > 1 ||
                    this.props.activeActions.segmentedShare.length < 1
                      ? ' pushes serão enviadas'
                      : ' push será enviada'}{' '}
                  </Typography>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="Essa quantidade é calculada de acordo com a segmentação escolhida por você na configuração da atividade. Além disso, não enviamos notificações para usuários que ultrapassaram o limite de participações."
                    placement="top-start">
                    <FontIcon>help</FontIcon>
                  </Tooltip>
                </Box>
              </MaterialGrid>
              <MaterialGrid item xs={12} style={{ marginTop: 10 }}>
                <Table
                  title="Extrato"
                  columns={this.state.columns}
                  data={this.props.activeActions.segmentedShare}
                  router={this.props.router}
                />
              </MaterialGrid>
              <MaterialGrid item xs={12}>
                <MaterialGrid item xs={12} style={{ marginBottom: 16, marginTop: 16 }}>
                  <Field
                    component={InputLabelOutlined}
                    label={`Título`}
                    name={`title`}
                    validate={[FORM_RULES.required, FORM_RULES.max(24)]}
                    required
                  />
                </MaterialGrid>
                <MaterialGrid item xs={12}>
                  <Field
                    component={InputLabelOutlined}
                    label={`Texto`}
                    name={`body`}
                    validate={[FORM_RULES.required]}
                    required
                    multiline
                  />
                </MaterialGrid>
              </MaterialGrid>
              <MaterialGrid container justify="flex-end">
                <PrimaryButton
                  type="submit"
                  color="primary"
                  disabled={this.props.activeActions.buttonLoading}
                  progress={parseInt(this.props.general.submitProgress)}>
                  Enviar Push
                </PrimaryButton>
              </MaterialGrid>
            </Form>
          </MaterialGrid>
        </MaterialGrid>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{'Enviar a notificação push?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Ao clicar em enviar, todos os usuários listados na tabela anterior serão notificados.
              Deseja continuar?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => this.handleSendPush()} color="primary" autoFocus>
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
      </MaterialGrid>
    );
  }
}

ShareToParticipants.propTypes = {
  action: PropTypes.shape({
    id: PropTypes.any,
  }),
  activeActions: PropTypes.shape({
    buttonLoading: PropTypes.any,
    loadingSegmentedShare: PropTypes.any,
    segmentedShare: PropTypes.shape({
      length: PropTypes.number,
    }),
  }),
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  formValues: PropTypes.shape({
    body: PropTypes.string,
    title: PropTypes.string,
  }),
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  getSegmentedUsers: PropTypes.func,
  handleSubmit: PropTypes.func,
  router: PropTypes.any,
  sendPushParticipants: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activeActions: state.activeActions,
    general: state.general,
    formValues: getFormValues('ShareToParticipantsPushForm')(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionDetail,
      sendTestMail,
      getSegmentedUsers,
      sendPushParticipants,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'ShareToParticipantsPushForm',
  })(ShareToParticipants)
);
