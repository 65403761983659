import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import promise from 'redux-promise';
import multi from 'redux-multi';
import thunk from 'redux-thunk';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import ReduxToastr from 'react-redux-toastr';
import reducers from './main/reducers';
import Routes from './main/routes';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
// import './styles/styles.scss';
import './styles/base/base.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'react-typist/dist/Typist.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-image-crop/dist/ReactCrop.css';
import 'moment/locale/pt-br'; // without this line it didn't work
import { appColors } from './styles/colors';

const {
  v2: { blue },
} = appColors;

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const store = applyMiddleware(multi, thunk, promise)(createStore)(reducers, devTools);

const theme = createTheme({
  palette: {
    primary: { main: blue },
    secondary: { main: '#ef2db3' },
    error: { main: '#FF5A52' },
    success: { main: '#52C22C' },
    text: {
      main: '#000000',
      lightgray: '#707070',
    },
    background: {
      main: '#F7F7F7',
      white: '#FFF',
      lightgray: '#707070',
    },
  },
  spacing: 8,
});

ReactDOM.render(
  <Provider store={store}>
    <>
      <MuiThemeProvider theme={theme}>
        <Routes />
      </MuiThemeProvider>
      <ReduxToastr
        timeOut={5000}
        newestOnTop
        preventDuplicates
        position="top-right"
        getState={(state) => state.toastr} // This is the default
        transitionIn="bounceIn"
        transitionOut="bounceOut"
        progressBar={false}
        closeOnToastrClick={false}
      />
    </>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.

// Learn more about service workers: http://bit.ly/CRA-PWA
// registerServiceWorker();
